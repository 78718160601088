import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../styles/index.scss'
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const TemplateWrapper = ({location, children }) => {
  const { title, description } = useSiteMetadata()
  const lang = location && location.pathname && location.pathname.indexOf('/fr') !== -1 ? 'fr' : 'en'
  let index = 0
  const frames = [
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjAgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEUwQjZBMEUxOTRDMTFFQUJCNzRDRDc0RkVDMkIwODAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEUwQjZBMEYxOTRDMTFFQUJCNzRDRDc0RkVDMkIwODAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBRTQzNjdCOTE5NEIxMUVBQkI3NENENzRGRUMyQjA4MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBRTQzNjdCQTE5NEIxMUVBQkI3NENENzRGRUMyQjA4MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkO6v+QAAAGySURBVHjaYmAYBaOAUsBIpDpmZmYVFRVpaWkODo5Pnz7dvXv3+fPnVHNFRkbGhQsXfv/+/R8G3r9/v3LlSi0tLUqNZmFhWbJkyX8cAGiNvb09RRZkZ2fDjXvw4EF7e3tWVlZXV9ebN28ggk+ePBESEiLTdGC437hxA2IQMNAlJSXhUmZmZt++fYNIJSQk4DGECY8cDw8PMBCugwHQ1cixeurUqStXrkDY6urq+AIZjxwwtdjY2EDYf//+RUl8jIzCwsIQNtAr1E/d9fX18Ljx8vKimrmCgoLR0dH79++Hm37x4kVOTk4qGG1paTllypSnT58iJ9Pbt29rampSarSBgcHOnTvRcsDHjx8nT54sJiZGqemmpqYfPnxANvrs2bPFxcXy8vJUCBZgPjhx4gTE3J8/f86ZM8fOzo6JiYlqUaqtrQ0x/evXrw4ODmQWNnjkFBQUIAxgnjpw4ACwXMKq7B8YkGMBKysrhKGnp3fz5k1cypqbm4EFIjkWwIMbWAeoqanhUiYuLk5mEH358uXRo0cEQxlYspJZowFTETs7O0ELfv369efPn9HGwSgYwgAgwAAZURrEKpL7XQAAAABJRU5ErkJggg==',
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjAgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEUwQjZBMTIxOTRDMTFFQUJCNzRDRDc0RkVDMkIwODAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEUwQjZBMTMxOTRDMTFFQUJCNzRDRDc0RkVDMkIwODAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0RTBCNkExMDE5NEMxMUVBQkI3NENENzRGRUMyQjA4MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0RTBCNkExMTE5NEMxMUVBQkI3NENENzRGRUMyQjA4MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PreN2OQAAADASURBVHjaYmAYBaOAUsBMkmpGRkYZGRk+Pr4fP378/fuXmg7h4ODw8PA4ePDgFzCwsLCgpumpqam3b9/+jwSsra2J1MtEjCJ9fX0VFRVkEaAd1LTg379/QPLWrVufPn2iSUrQ0NAwMTHh5OS8cuUKJIisrKyI1MtCjKIbN25AVbOwkOo4JhKUMjGR4XsmWme0UQtGLRh2FrCyspKa6UjL+g8ePICUFt+/fye2jiK12oG4HVijQYrYUTAKhgMACDAA3A5FZLkW/jcAAAAASUVORK5CYII=',
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjAgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEUwQjZBMTYxOTRDMTFFQUJCNzRDRDc0RkVDMkIwODAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEUwQjZBMTcxOTRDMTFFQUJCNzRDRDc0RkVDMkIwODAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0RTBCNkExNDE5NEMxMUVBQkI3NENENzRGRUMyQjA4MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0RTBCNkExNTE5NEMxMUVBQkI3NENENzRGRUMyQjA4MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PltxvzwAAAH1SURBVHja7FZNi0FRGJ5BsSFKiZISKykklGwoOx9ZWNrYKf+BfyDFxs7CQkpS9rLDwveGlESSj1nIRz5mnplT093MHXPd1eRZnXvec973Pc953vfcl5cnnngUr3euk8lkarVaIpFcLpfFYjEcDg+HAzspGI3GQqGw2WzeKRiNRvF4XCgUPurd7Xbvdrv3H9BoNORyOXOKQEiv11MoFBjfbrdKpYJPLpdrs9kcDgdZUywWA4EAw/TD4fB3sh6Ph2pKpVJk/nw+q1QqGic8GtvpdMrn8xgMBoNyuUw1pdPpSCTyuZ/Hk0qlk8mEZfG5XC5ygv1+/+s13K3oLwgEArvd3u/3SYBEIsGOd4PB0Ol0ut3ueDwmrrfbbSwWA0X0G3l3BhCLxXq9njpzPB6n0ynqjn4j935+OBxOvV5vtVrr9VqpVCKk1+vV6XSlUul6vbJ8yVardbVaEa6i0SiTE4DcYDBosVjQKt6+QLXOZjOtVmsymTDm8/nZbPbPOSIAvNBIJZfLEWu1WmXIQyaTIS7Qjvx+P9Xk8/lQAcSKqmbYi0BCs9kUiUTks1arQaloSrhYp9NJJqEis9ncbrcZHgKO5vP5T90USg2FQo8KBopMJpOQPNU1JIQ2hdxZe9FAlEajwbuGakAdoJ6Xy+Xzh+CJf4IPAQYAMQtH98Mqw2AAAAAASUVORK5CYII=',
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyVpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjAgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjEyN0QzMkIxOTRDMTFFQUJCNzRDRDc0RkVDMkIwODAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjEyN0QzMkMxOTRDMTFFQUJCNzRDRDc0RkVDMkIwODAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0RTBCNkExODE5NEMxMUVBQkI3NENENzRGRUMyQjA4MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2MTI3RDMyQTE5NEMxMUVBQkI3NENENzRGRUMyQjA4MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PhPSDqMAAAHFSURBVHja7FZLqwFhGGZMopRSasrSwq0UG36ApOS28S8s2Vr5LTZiYcHCloUVUi5bx5JcIhzMecxbk3OcnGG+zal5Vs+83zfv5ZlnvhmdToMGtdAr32oymXieBxFFcbfbMW7EarX2+/0PCbPZLBgMMi6QyWTEO5RKJcYFyuXyfYHhcGgwGJhlt9lsi8VC/A6FKnFKNkUiEdQAabfb9XqdgqlUitkElUqFus7lcvLD6PV6HMcxyG6321erFTJer1eXy4VR4FG69Pv9DCSKRqPwKAhsOp1Ol8slhLq9QXq9EpX+LpDNZonUajU0DlKtVimSTqdRRpU+giBst1vkvVwuXq+Xgg6HY7/fU9Dn86maIBaLWSwWEIizXq8FCcg7GAxuN3NcMplUNUGj0SDPnE4nFNhIADkejxTvdrvvqyRL8QTn89ntdj9Jwj9Zi8fjZrMZZDKZ5PN5esIycBzBpjgwEonEeDx+Z4JWq0VtFovFx9VCoUCrnU7nnexOpxOvEqUIBAKPG+AfWSiPx/OyROFweD6fowb8Q575gdFo1Gw24V14KRQK4fK1Lxq+X3TUfEr4vTueNxqNIOjjcDho/wca/iu+BBgAwfE+7nNLX8wAAAAASUVORK5CYII='
  ]
  const updateIcon = () => {
    index = index >= frames.length - 1 ? 0 : index + 1
    const oldLink = document.getElementById('favicon')
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    const link = document.createElement('link')
    link.id = 'favicon'
    link.rel = 'shortcut icon'
    link.href = frames[index]
    try{
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
    } catch(e) {}
    document.head.appendChild(link);
    const timer = setTimeout(updateIcon, index === (frames.length - 1) ? 1000 : 750)
    if(typeof window !== 'undefined') {
      if (window.iconTimer) {
        clearTimeout(window.iconTimer)
      }
      window.iconTimer = timer
    }
  }
  const timer = setTimeout(updateIcon, 500)
  if(typeof window !== 'undefined') {
    if (window.iconTimer) {
      clearTimeout(window.iconTimer)
    }
    window.iconTimer = timer
  }
  return (
    <div>
      <Helmet>
        <html lang={lang} />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="apple-touch-icon" sizes="57x57" href={`${withPrefix('/')}img/apple-icon-57x57.png`}/>
        <link rel="apple-touch-icon" sizes="60x60" href={`${withPrefix('/')}img/apple-icon-60x60.png`} />
        <link rel="apple-touch-icon" sizes="72x72" href={`${withPrefix('/')}img/apple-icon-72x72.png`} />
        <link rel="apple-touch-icon" sizes="76x76" href={`${withPrefix('/')}img/apple-icon-76x76.png`} />
        <link rel="apple-touch-icon" sizes="114x114" href={`${withPrefix('/')}img/apple-icon-114x114.png`} />
        <link rel="apple-touch-icon" sizes="120x120" href={`${withPrefix('/')}img/apple-icon-120x120.png`} />
        <link rel="apple-touch-icon" sizes="144x144" href={`${withPrefix('/')}img/apple-icon-144x144.png`} />
        <link rel="apple-touch-icon" sizes="152x152" href={`${withPrefix('/')}img/apple-icon-152x152.png`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`${withPrefix('/')}img/apple-icon-180x180.png`} />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image-03.png`}
        />
      </Helmet>
      <Navbar location={location} />
      {children}
      <Footer location={location} />
    </div>
  )
}

export default TemplateWrapper
