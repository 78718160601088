import React from 'react'
import { Link } from 'gatsby'

const Navbar = class extends React.Component {
  render() {
    const lang = this.props.location && this.props.location.pathname && this.props.location.pathname.indexOf('/fr') !== -1 ? 'fr' : 'en'

    return (
      <header
        className="cNavbar"
      >
        <div className="cNavbar-menu container">
          <ul className="cNavbar-list">
            <li className="cNavbar-list-item hide-on-mobile">
              <h2 className="cNavbar-item">Design</h2>
              </li>
            <li className="cNavbar-list-item">
              <h1 className="cNavbar-item">
                <Link to={lang === 'fr' ? '/fr/' : '/'}>213A</Link>
              </h1>
            </li>
            <li className="cNavbar-list-item hide-on-mobile">
              <h2 className="cNavbar-item">{ lang === 'en' ? 'Development' : 'Développement' }</h2>
            </li>
          </ul>
        </div>
      </header>
    )
  }
}

export default Navbar
