import React from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

const Footer = class extends React.Component {
  render(props) {
    const lang = this.props.location && this.props.location.pathname && this.props.location.pathname.indexOf('/fr') !== -1 ? 'fr' : 'en'
    return (
      <footer className="cFooter">
        <div className="cFooter-inner container">
          <Fade>
            <div className="cFooter-header">
              <h2 className="cFooter-title title">{ lang === 'en' ? (<span>Now, we’d love to hear about <em>you.</em></span>) : (<span>Maintenant, parlons de <em>vous.</em></span>)}</h2>
            </div>
          </Fade>
          <div className="cFooter-columns">
            <Fade>
              <div className="cFooter-column">
                <h3 className="cFooter-heading text">{ lang === 'en' ? 'Chat' : 'Discuter' }</h3>
                <div className="cFooter-content">
                  <a href={`mailto:${ lang === 'en' ? 'hello' : 'bonjour' }@213a.ca`} className="cFooter-text cFooter-no-underline link text">{`${ lang === 'en' ? 'hello' : 'bonjour' }@213a.ca`}</a>
                </div>
              </div>
              <div className="cFooter-column">
                <h3 className="cFooter-heading text">{ lang === 'en' ? 'Connect' : 'Connecter' }</h3>
                <div className="cFooter-content">
                  <a href="https://www.google.com/maps/place/213A+Creative+Studio/@45.5291138,-73.6022133,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc919429c9358a1:0x703510427a69913e!8m2!3d45.5291101!4d-73.6000246" target="_blank" rel="noopener noreferrer" className="cFooter-text cFooter-no-underline text">5795 avenue de Gaspé <br/>Unit 213-A <br/>Montréal, QC, Canada <br/>H2S 2X3</a>
                </div>
              </div>
              <div className="cFooter-column">
                <h3 className="cFooter-heading text">{ lang === 'en' ? 'Create' : 'Créer' }</h3>
                <div className="cFooter-content">
                  <p className="cFooter-text text">{ lang === 'en' ? `How can we help?` : `Pouvons-nous vous aider?` }</p>
                  <a href={`mailto:${ lang === 'en' ? 'hello' : 'bonjour' }@213a.ca`} className="cFooter-text text">{ lang === 'en' ? `Start a project` : `Démarrons un projet`}</a>
                </div>
              </div>
            </Fade>
          </div>
          <div className="cFooter-row">
          {
             lang === 'fr' ? 
              <Link to="/fr/travailler" className="cFooter-text text">Postuler</Link>
              :
              <Link to="/jobs/" className="cFooter-text text">Work with us</Link>
            }
            <p className="cFooter-text hide-on-mobile text">213A</p>
            {
             lang === 'fr' ? 
              <Link to="/" className="cFooter-text text">English</Link>
              :
              <Link to="/fr/" className="cFooter-text text">En Francais <abbr title="S'il vous plaît">SVP</abbr></Link>
            }
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
