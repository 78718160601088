import React from 'react'
import Fade from 'react-reveal/Fade'

const About = class extends React.Component {
  render() {
    return (
      <section className="cAbout">
        <div className="cAbout-inner container">
          <Fade><h2 className="cAbout-title title" dangerouslySetInnerHTML={{ __html: this.props.title }} /></Fade>
          <Fade><div className="cAbout-text text" dangerouslySetInnerHTML={{ __html: this.props.text }} /></Fade>
        </div>
      </section>
    )
  }
}

export default About
